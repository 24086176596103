import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import { Row, Col, PageHeader, Clearfix } from 'react-bootstrap';
import Alerts from '../Alerts';
import ReleaseItem from './Item';
import { list, search, SearchApi } from '../../actions/release';
import FilterSidebar from './FilterSidebar'
import ReleaseHeader from './ReleaseHeader'
import { LoadMorePager, HomeNavigation, SearchResult, getTitle, page_metas } from '../Helper'
import { KicksonfireBlock } from './Block'
import PropTypes from 'prop-types'
import Breadcrumb from '../Breadcrumb';

class Base extends Component {
    constructor(props) {
        super(props);
        const location = props.location;
        const api = props.hasOwnProperty("api") && props.api != undefined ? props.api : 'shop';
        let params = Object.assign({}, location.search ? qs.parse(location.search.replace('?', '')) : {});
        let page = params.hasOwnProperty("page") ? parseInt(params.page) : 1;
        let keyword = props.hasOwnProperty("keyword") && props.keyword != undefined ? props.keyword.trim() : '';;// params.hasOwnProperty("q") ? params.q.trim() : "";
        let sortby = params.hasOwnProperty("sort") ? params.sort : "popular"
        let sortbyList = this.generateListSortBy(keyword, api);
        let selectedBrands = params.hasOwnProperty("brand") && params.brand.trim().length > 0 ? params.brand.toLowerCase().split(",") : [];
        let selectedGenders = params.hasOwnProperty("gender") && params.gender.trim().length > 0 ? params.gender.toLowerCase().split(",") : [];
        let selectedSizes = params.hasOwnProperty("size") && params.size.trim().length > 0 ? params.size.split(",").map(value => parseFloat(value)) : [];
        let selectedShipping = params.hasOwnProperty("shipping") && params.shipping.trim() ? params.shipping.toLowerCase().trim() : "";

        this.state = {
            page: page > 1 ? page : 1,
            perPage: 24,
            pageCount: 1,
            keyword: keyword,
            brands: selectedBrands,
            genders: selectedGenders,
            sizes: selectedSizes,
            shipping: selectedShipping,
            sortby: sortby,
            columnFilterShow: false,
            sortbyList: sortbyList,
            bestSellers: [],
        };
        if(this.props.showBestSellers && this.state.page == 1 && this.props.api==='latest'){
            this.getBestSellers()
        }
    }

    componentDidMount() {
        if(!this.props.isServer){
            this.handleFetch();
        }else{
            this.props.clearServerLoad();
        }
    }

    componentWillUnmount() {
        this.props.clearAlerts();
        this.props.clearReleases();
    }

    componentDidUpdate(prevProps, prevState) {
        let cloneCurState = { ...this.state }
        let clonePreState = { ...prevState }

        cloneCurState.columnFilterShow = false;
        clonePreState.columnFilterShow = false;

        if (JSON.stringify(cloneCurState).toLowerCase().trim() != JSON.stringify(clonePreState).toLowerCase().trim()) {
            this.handlePageChange();
            this.handleFetch();
        }else if (JSON.stringify({pathname: prevProps.location.pathname, search: prevProps.location.search}).toLowerCase().trim() !== JSON.stringify({pathname: this.props.location.pathname, search: this.props.location.search}).toLowerCase().trim()) {
            this.check_for_state_change(this.props);
        }
        if( this.props.showBestSellers && this.props.api ==='latest' && this.state.page == 1  ){
            if(this.state.bestSellers.length == 0 ){
                this.getBestSellers()
            }
        }else{
            if(this.state.bestSellers.length>0){
                this.setState({
                    bestSellers: [],
                });
            }
        }
    }

    check_for_state_change(props) {
        const query = Object.assign({}, qs.parse(props.location.search.replace('?', '')));
        const page = query.hasOwnProperty("page") ? parseInt(query.page) : 1;
        const brands = query.hasOwnProperty("brand") ? query.brand.trim().toLowerCase().split(',').map(value => value.toLowerCase()) : [];
        const genders = query.hasOwnProperty("gender") ? query.gender.trim().toLowerCase().split(',') : [];
        const sizes = query.hasOwnProperty("size") ? query.size.trim().toLowerCase().split(',').map(value => parseFloat(value)) : [];
        const shipping = query.hasOwnProperty("shipping") ? query.shipping.toLowerCase().trim() : "";
        const sortby = query.hasOwnProperty("sort") ? query.sort.toLowerCase().trim() : "popular";

        let keyword = '';
        if(query.hasOwnProperty('q') && query.q.length>0){
            keyword =  query.q.toLowerCase().trim()
        }else if(query.hasOwnProperty('keyword')  && query.keyword.length>0){
            keyword =  query.keyword.toLowerCase().trim()
        }

        let newState = {};   
        if (keyword.toLowerCase().trim() != this.state.keyword) {
            newState.keyword = keyword.toLowerCase().trim();
            newState.page = 1;
        }
        if (JSON.stringify(brands).toLowerCase().trim() != JSON.stringify(this.state.brands).toLowerCase().trim()) {
            newState.brands = brands;
            newState.page = 1;
        }
        if (JSON.stringify(genders).toLowerCase().trim() != JSON.stringify(this.state.genders).toLowerCase().trim()) {
            newState.genders = genders;
            newState.page = 1;
        }
        if (JSON.stringify(sizes).toLowerCase().trim() != JSON.stringify(this.state.sizes).toLowerCase().trim()) {
            newState.sizes = sizes;
            newState.page = 1;
        }
        if (shipping.toLowerCase().trim() != this.state.shipping.toLowerCase().trim()) {
            newState.shipping = shipping;
            newState.page = 1;
        }
        if (sortby.length>0 && sortby.toLowerCase().trim() !== this.state.sortby.toLowerCase().trim()) {
            newState.sortby = sortby;
            newState.page = 1;
        }
        if (parseInt(page) != parseInt(this.state.page)) {
            newState.page = page;
        }
        if (Object.keys(newState).length > 0) {
            console.log(newState);
            this.setState(newState);
        }
    }

    getBestSellers() {
        let body = {
            perpage: 4,
            exclude_fields:["updated_on", "created_on", "brand", "tags", "style_code"],
            sort: {
                by: "popular",
                order: "desc"
            }
        }
        SearchApi(body,
            (result) => {
                this.setState({
                    bestSellers: result.data,
                });
            },
            (err) => {
            })
    }

    generateListSortBy(key, api) {
        let sortItems = [];
        switch (api) {
            case 'under_retail':
                sortItems = [
                    { value: 'popular', label: 'Popular' },
                    { value: 'discount-desc', label: 'Highest % Off ' },
                    { value: 'price-asc', label: 'Price (Low-High)' },
                    { value: 'price-desc', label: 'Price (High-Low)' },
                ];
                break;
            case 'sale':
                sortItems = [
                    { value: 'popular', label: 'Popular' },
                    { value: 'discount-desc', label: 'Highest % Off ' },
                    { value: 'price-asc', label: 'Price (Low-High)' },
                    { value: 'price-desc', label: 'Price (High-Low)' },
                ];
                break;                
            default:
                if (key == "") {
                    sortItems = [
                        { value: 'popular', label: 'Popular' },
                        { value: 'past', label: 'Past' },
                        { value: 'upcoming', label: 'Upcoming' },
                        { value: 'price-asc', label: 'Price (Low-High)' },
                        { value: 'price-desc', label: 'Price (High-Low)' },
                    ];
                } else {
                    sortItems = [
                        { value: 'relevant', label: 'Relevant' },
                        { value: 'popular', label: 'Popular' },
                        { value: 'past', label: 'Past' },
                        { value: 'upcoming', label: 'Upcoming' },
                        { value: 'price-asc', label: 'Price (Low-High)' },
                        { value: 'price-desc', label: 'Price (High-Low)' },
                    ];
                }
                break;
        }
        return sortItems;
    }

    handlePageChange() {
        let params = {};
        if (this.state.keyword.length > 0) {
            params.keyword = this.state.keyword.trim();
        }

        if (this.state.brands.length > 0) {
            params.brand = this.state.brands.join(",");
        }

        if (this.state.genders.length > 0) {
            params.gender = this.state.genders.join(",");
        }

        if (this.state.sizes.length > 0) {
            params.size = this.state.sizes.join(",");
        }

        if (this.state.shipping.length > 0) {
            params.shipping = this.state.shipping;
        }

        if (this.state.sortby.length > 0 && this.state.sortby != "popular") {
            params.sort = this.state.sortby;
        }
        if (this.state.page > 1) {
            params.page = parseInt(this.state.page);
        }

        const query = Object.assign({}, params);
        const searchString = qs.stringify(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: searchString,
        });
    }

    handleFetch() {
        let brandIds = [];
        if(this.state.brands.length > 0){
            for(let brandName of this.state.brands){
                for(let category of this.props.categories){
                    if(category.name.toLowerCase() == brandName.toLowerCase()){
                        brandIds.push(parseInt(category.id));
                    }
                }
            }
        }
        let body = {
            page: this.state.page,
            perpage: this.state.perPage,
            api: this.props.api,
            keyword: this.state.keyword,
            sortby: this.state.sortby,
            filter: {
                country: this.props.country,
                brands: brandIds,
                genders: this.state.genders,
                sizes: this.state.sizes,
                shipping: this.state.shipping
            }
        };

        switch (this.props.api) {
            case 'latest':
                body.sortby = 'created-desc';
                break;
            default:
                break;
        }
        this.props.defaultReleases(body, true, this.props.influencer);
    }

    toggleFilter() {
        this.setState({
        }, () => {
            if (this.state.columnFilterShow) {
                this.closeFilter()
            } else {
                this.openFilter();
            }
        })
    }

    closeFilter() {
        this.setState({
            columnFilterShow: false,
        });
        document.getElementsByTagName('body')[0].classList.remove('filter-open');
    }

    openFilter() {
        this.setState({
            columnFilterShow: true
        });
        document.getElementsByTagName('body')[0].classList.add('filter-open');
    }

    setSortby(value) {
        this.setState({
            sortby: value,
            page: 1,
            stateChange: "sortby"
        });
    }

    setBrands(value) {
        this.setState({
            brands: value,
            page: 1,
            stateChange: "brands"
        });
    }

    setGenders(value) {
        this.setState({
            genders: value,
            page: 1,
            stateChange: "genders"
        });
    }

    setSizes(value) {
        this.setState({
            sizes: value,
            page: 1,
            stateChange: "sizes"
        });
    }

    setShipping(value) {
        this.setState({
            shipping: value,
            page: 1,
            stateChange: "shipping"
        });
    }

    setPage(page = 1) {
        this.setState({
            page: page
        });
    }

    clearFilter() {
        this.setState({
            page: 1,
            brands: [],
            genders: [],
            sizes: [],
            shipping: "",
            stateChange: "clearFilter",
        });
        let query = Object.assign({}, qs.parse(this.props.location.search.replace('?', '')));
        if (query.hasOwnProperty('page')) {
            delete query.page;
        }
        if (query.hasOwnProperty('brand')) {
            delete query.brand;
        }
        if (query.hasOwnProperty('gender')) {
            delete query.gender;
        }
        if (query.hasOwnProperty('size')) {
            delete query.size;
        }
        if (query.hasOwnProperty('shipping')) {
            delete query.shipping;
        }
        const searchString = qs.stringify(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: searchString,
        });
    }

    render() {
        const country = this.props.country;
        const releases = this.props.items.length > 0 ? this.props.items.map((release, index) => {
            return (
                <ReleaseItem item={release} key={index} country={country} />
            );
        }) : this.props.noitems ? (
            <Col lg={12} className="noitems">No items found.</Col>
        ) : '';
        let isTitleVisible = 'page-title' +(this.props.showTitle ? '':' hidden') ;
        let isFilterVisible = true;
        let isSortVisible = true;
        let description = '';
        switch(this.props.api){
            case 'latest':
                isFilterVisible = true;
                isSortVisible = false;
                isTitleVisible += ' center';
                description = page_metas.latest.description;
                break;
        }

        const bestSellers = this.props.showBestSellers?(this.state.bestSellers.length > 0 ? <KicksonfireBlock id="best-sellers" className="best-sellers-wrapper" title={"Best Sellers"} items={this.state.bestSellers} country={this.props.country} showAll={true} showAllLink="/shop" />: ''):'';

        let breadcrumb = [];
        let pageTitle = '';
        
        if(this.props.metatags?.title?.length>0){
            pageTitle = this.props.metatags.title.replace(' - ' + getTitle(this.props.influencer), '');
            breadcrumb.push({
                title: pageTitle,
                url: '/'+this.props.api
            });
        }
        return (
            <div id="page-releases" className={this.props.api}>
                {bestSellers}
                <Alerts alerts={this.props.alerts} />
                {this.props.api == 'latest' ? HomeNavigation({routing : this.props.routing}): <Breadcrumb items={breadcrumb} />}
                {pageTitle !='' ?<PageHeader bsClass="md" className={isTitleVisible}>{pageTitle}</PageHeader>:''}
                {description.length>0?<div className='description' dangerouslySetInnerHTML={{__html: '<p>'+description.replace('\n\r', '</p><p>')+'</p>'}} />:''}
                {SearchResult({keyword: this.state.keyword, total:this.props.total })}
                {isFilterVisible || isSortVisible? <ReleaseHeader isSortVisible={isSortVisible} isFilterVisible={isFilterVisible} toggleFilterAction={() => this.toggleFilter()} setSortby={(value) => this.setSortby(value)} sortby={this.state.sortby} listSortby={this.state.sortbyList} isFilterShow={this.state.columnFilterShow} /> :''}
                <Row id="row-container" className={this.state.columnFilterShow == true ? "open-filters" : ""}>
                {isFilterVisible?
                    <div id="column-filter" className="col-lg-3">
                        <FilterSidebar
                            toggleSidebar={() => this.toggleFilter()}
                            setBrands={(value) => this.setBrands(value)}
                            setGenders={(value) => this.setGenders(value)}
                            setSizes={(value) => this.setSizes(value)}
                            setShipping={(value) => this.setShipping(value)}
                            clearFilter={() => this.clearFilter()}
                            categories={this.props.categories}
                            brands={this.state.brands}
                            genders={this.state.genders}
                            sizes={this.state.sizes}
                            shipping={this.state.shipping}
                            api={this.props.api}
                            showBrand={true} />
                    </div>:''}
                    <div id="release-filter" className="col-lg-12">
                        <div className="releases-container">
                            {releases}
                            <Clearfix />
                        </div>
                        <LoadMorePager pages={this.props.pageCount} current={this.state.page} setPage={(value)=>this.setPage(value)} location={this.props.location} />
                    </div>
                </Row>
                {isFilterVisible?
                <div className="btn-filter-wrapper-float">
                    <button id="search-filter" name="searc_filter" aria-label="Search Filters" className="btn btn-snkrly-dark btn-block" style={{ display: this.state.columnFilterShow ? "none" : "" }} onClick={() => this.toggleFilter()}>
                        <i className="fa fa-sliders-h fa-rotate-90" aria-hidden="true"></i>
                    </button>
                </div>
                :''}
            </div>
        );
    }

}

Base.contextTypes = {
    router: PropTypes.object.isRequired
}

Base.propTypes = {
    location: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    let props = {
        alerts: state.alerts,
        influencer: state.influencer,
        items: state.releases.items,
        pageCount: state.releases.pageCount,
        page: state.releases.page,
        total: state.releases.total,
        metatags: state.metatags,
        noitems: state.settings.noitems,
        routing: state.routing,
        categories: state.settings.categories,
        isServer: state.settings.isServer,
        country: state.settings.location ? state.settings.location.snkrly_country_code : ''
    };
    return props;
};

const mapDispatchToProps = (dispatch) => {
    return {
        defaultReleases: (request, showloader, influencer) => {
            switch (request.api) {
                case "shop":
                case "search":
                case "under_retail":
                case "latest":
                    dispatch(search(request, showloader, influencer));
                    break;
                default:
                    dispatch(list(request, showloader, influencer));
                    break;
            }
        },
        shopReleases: (request) => {
            dispatch(shop(request));
        },
        clearReleases: () => {
            dispatch({ type: 'CLEAR_RELEASES' });
        },
        clearServerLoad: () => {
            dispatch({ type: 'SERVER_LOAD_END' });
        },        
        clearAlerts: () => {
            dispatch({ type: 'CLEAR_ALERTS' });
        }
    }
}

const ReleaseSearch = (props) => {
    const query = Object.assign({}, qs.parse(props.location.search.replace('?', '')));
    let keyword = '';
    if(query.hasOwnProperty('q') && query.q.length>0){
        keyword =  query.q.toLowerCase().trim()
    }else if(query.hasOwnProperty('keyword')  && query.keyword.length>0){
        keyword =  query.keyword.toLowerCase().trim()
    }
    const showTitle = props.hasOwnProperty('showTitle') ? props.showTitle : true;
    return (
        <Releases api="search" showTitle={showTitle} keyword={keyword} {...props} />
    )
}

const ReleaseUnderRetail = (props) => {
    const query = Object.assign({}, qs.parse(props.location.search.replace('?', '')));
    let keyword = '';
    if(query.hasOwnProperty('q') && query.q.length>0){
        keyword =  query.q.toLowerCase().trim()
    }else if(query.hasOwnProperty('keyword')  && query.keyword.length>0){
        keyword =  query.keyword.toLowerCase().trim()
    }
    const showTitle = props.hasOwnProperty('showTitle') ? props.showTitle : true;
    return (
        <Releases api="under_retail" showTitle={showTitle} keyword={keyword} {...props} />
    )
}

const ReleaseShop = (props) => {
    const query = Object.assign({}, qs.parse(props.location.search.replace('?', '')));
    let keyword = '';
    if(query.hasOwnProperty('q') && query.q.length>0){
        keyword =  query.q.toLowerCase().trim()
    }else if(query.hasOwnProperty('keyword')  && query.keyword.length>0){
        keyword =  query.keyword.toLowerCase().trim()
    } 
    const showTitle = props.hasOwnProperty('showTitle') ? props.showTitle : false;
    return (
            <Releases api="shop" showTitle={showTitle} keyword={keyword} {...props} />
    )
}

const ReleaseUpcoming = (props) => {
    return (
        <Releases api="list" type='upcoming' {...props} />
    )
}

const ReleasePast = (props) => {
    return (
        <Releases api="list" type='past' {...props} />
    )
}

const ReleaseLatest = (props) => {
    const showTitle = props.hasOwnProperty('showTitle') ? props.showTitle : true;
    const showBestSeller = props.hasOwnProperty('showBestSeller') && props.showBestSeller ? props.showBestSeller : false;
    return (
        <Releases api="latest" showBestSellers={showBestSeller} showTitle={showTitle} {...props} />
    )
}

// export const Releases = withRouter(Base);
export const Releases = withRouter(connect(mapStateToProps, mapDispatchToProps)(Base));
export const Upcoming = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReleaseUpcoming));
export const Past = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReleasePast));
export const Shop = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReleaseShop));
export const Search = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReleaseSearch));
export const UnderRetail = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReleaseUnderRetail));
export const Latest = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReleaseLatest));

